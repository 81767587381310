import React, { useState } from 'react';
import { Box, Grid, Typography, Dialog, Slide, Paper } from '@mui/material';
import ProjectCard from './ProjectCard';
import projects from './projects.json';
import PiDoorbell from './pages/PiDoorbell';
import RpiRallyComputer from './pages/RpiRallyComputer';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Projects() {
  const [displayedProject, setDisplayedProject] = useState(null);

  const handleDisplayProject = (projectId) => {
    setDisplayedProject(projectId);
  };

  const projectCards = Object.keys(projects).map((projectId) => {
    const project = projects[projectId];
    return (
      <Grid key={projectId} item>
        <ProjectCard
          projectId={projectId}
          onProjectClick={handleDisplayProject}
          title={project.title}
          summary={project.summary}
          image={project.image}
          tags={project.tags}
        />
      </Grid>
    );
  });

  const getProjectPage = () => {
    switch (displayedProject) {
      case 'rpi-video-doorbell':
        return <PiDoorbell />;
      case 'rpi-rally-computer':
        return <RpiRallyComputer />;
      default:
        return <Typography variant='h6'>No Content Yet!</Typography>;
    }
  };

  return (
    <Box padding={3} id='Projects'>
      <Typography variant='h5'>Hobby Projects</Typography>
      <Grid
        container
        spacing={3}
        marginTop={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch'
        }}
      >
        {projectCards}
      </Grid>
      <Dialog
        open={displayedProject}
        keepMounted
        TransitionComponent={Transition}
        fullWidth
        maxWidth={'xl'}
        onClose={() => handleDisplayProject(null)}
      >
        <Paper sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
          {getProjectPage()}
        </Paper>
      </Dialog>
    </Box>
  );
}

export default Projects;