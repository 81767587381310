/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import RRComputerModel from './RRComputerModel';
import ModelViewer from '../../ModelViewer';
import { Header } from '../../utils';

function PiDoorbell() {
  return (
    <>
      <Header isTitle textAlign='center'>RPi Rally Computer</Header>
      <ModelViewer rotation={[0, 0.2, 0]}>
        <RRComputerModel />
      </ModelViewer>
      <Box width='90%'>
        <Header>Requirements</Header>
        <Typography>
          
        </Typography>
        <Header>Design & Implementation</Header>
        <Typography>

        </Typography>
        <Header>Operation & Error Flow</Header>
        <Box display='flex' justifyContent='center' flexDirection='column'>
        </Box>
        <Header>Learning & Improvement</Header>
        <Grid container>
          <Grid item lg alignItems='center'>
            <Typography>

            </Typography>
          </Grid>
          <Grid item lg display='flex' justifyContent='center'>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PiDoorbell;