import React from 'react';
import HeaderBar from './HeaderBar';
import { Container, Paper } from '@mui/material';
import Projects from './Projects';
import Experience from './Experience';
import Contact from './Contact';

function App() {
  return (
    <Container maxWidth='xl' disableGutters>
      <Paper elevation={10}>
        <HeaderBar />
        <Projects />
        <Experience />
        <Contact />
      </Paper>
    </Container>
  );
}

export default App;