import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box, Slider, Stack, useMediaQuery } from '@mui/material';
import { PresentationControls, Stage } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Compress, Expand } from '@mui/icons-material';

function ModelViewer(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [explodeOffset, setExplodeOffset] = useState(0);

  const childrenWithProps = React.Children.map(props.children, child => {
    return React.cloneElement(child, { explodeOffset: explodeOffset });
  });

  return (
    <Box sx={{ width: isMobile ? '100%' : '50%' }}>
      <Canvas style={{ height: '40vh' }} shadows camera={{ position: [0, 0, 10], fov: 25, zoom: 0.8 }}>
        <Stage
          intensity={0.5}
          preset="rembrandt"
          environment="city"
        >
          <PresentationControls
            global
            config={{ mass: 2, tension: 500 }}
            speed={isMobile ? 5 : 1}
            rotation={props.rotation}
            polar={[-Math.PI / 3, Math.PI / 3]}
            azimuth={[-Math.PI / 1.4, Math.PI / 2]}
          >
            {childrenWithProps}
          </PresentationControls>
        </Stage>
      </Canvas>
      <Stack
        spacing={2}
        direction='row'
        alignItems='center'
      >
        <Compress sx={{ transform: 'rotate(90deg)' }} fontSize='large' />
        <Slider color='success' value={explodeOffset} onChange={(event, value) => setExplodeOffset(value)} />
        <Expand sx={{ transform: 'rotate(90deg)' }} fontSize='large' />
      </Stack>
    </Box >
  );
}

ModelViewer.propTypes = {
  children: PropTypes.object,
  rotation: PropTypes.array
};

export default ModelViewer;