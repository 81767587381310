import React from 'react';
import PropTypes from 'prop-types';
import { Gltf } from '@react-three/drei';

function DoorbellModel(props) {
  const exOffst = props.explodeOffset / 10;
  return (
    <group>
      <Gltf src='/assets/PiDoorbell/bracket.glb' position={[0, 0, -exOffst * 6]} />
      <group>
        <Gltf src='/assets/PiDoorbell/backbody.glb' position={[0, 0, -exOffst * 4]} />
        <Gltf src='/assets/PiDoorbell/camera.glb' position={[0, 0, exOffst * 3]} />
        <Gltf src='/assets/PiDoorbell/fan.glb' position={[0, 0, -exOffst * 1]} />
        <Gltf src='/assets/PiDoorbell/pizero.glb' position={[0, 0, exOffst * 2]} />
        <Gltf src='/assets/PiDoorbell/nvlights.glb' position={[0, 0, exOffst * 1]} />
      </group>
      <Gltf src='/assets/PiDoorbell/frontbody.glb' position={[0, 0, exOffst * 5]} />
      <Gltf src='/assets/PiDoorbell/button.glb' position={[0, 0, exOffst * 7]} />
    </group>
  );
}

DoorbellModel.propTypes = {
  explodeOffset: PropTypes.number
};

export default DoorbellModel;