import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Chip, Grid, Typography, useMediaQuery } from '@mui/material';
import { blue, pink, purple, orange, green, red, cyan, lime, teal, yellow } from '@mui/material/colors';
import { Timeline, TimelineDot, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';

function getRandomColor(lastColor) {
  const colors = [blue, pink, purple, orange, green, red, cyan, lime, teal, yellow];
  let randomColor = colors[Math.floor(Math.random() * colors.length)];
  while (randomColor[500] === lastColor) {
    randomColor = colors[Math.floor(Math.random() * colors.length)];
  }

  return randomColor;
}

function TimelineJob(color, name, yStart, yEnd) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <TimelineItem key={name}>
      <TimelineOppositeContent color='text.secondary'>
        <Typography>{yStart} - {yEnd}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot sx={{ backgroundColor: color }} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography
          fontWeight={700}
          variant='h6'
          maxWidth={isMobile ? 120 : null}
          marginLeft={isMobile ? -2 : null}
        >
          {name}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

function ClickableChip(tag) {
  const [color, setColor] = useState('default');

  return (
    <Chip
      avatar={<Avatar alt={tag} src={`/images/icons/${tag.replace(/\s/g, '').toLowerCase()}.svg`} />}
      clickable
      onClick={() => {
        const newColor = getRandomColor();
        setColor(newColor[500]);
      }}
      variant='outlined'
      label={tag}
      sx={{
        backgroundColor: color,
        height: 'auto',
        fontSize: 16,
        '& .MuiChip-avatar': {
          width: 35, height: 35
        },
      }}
    />
  );
}

function TimelineRole(color, name, isLast) {
  return (
    <TimelineItem key={name}>
      <TimelineOppositeContent>
        <Typography>{name}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot variant='outline' sx={{ color: color }} />
        {!isLast &&
          <TimelineConnector />
        }
      </TimelineSeparator>
      <TimelineContent>
      </TimelineContent>
    </TimelineItem>
  );
}

function Experience() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const skills = [
    'JavaScript',
    'NodeJS',
    'React',
    'Java',
    'Groovy',
    'Ruby on Rails',
    'Python',
    'Google Cloud Platform',
    'BigQuery',
    'Firebase',
    'MySQL',
    'HTML',
    'CSS',
    'JSON Schema',
    'C#',
    'Elasticsearch',
    'Kibana',
    'Jenkins',
    'Blender',
    'Corel Draw x4',
    'Autodesk Inventor',
    'Unity'
  ];

  const career = [
    {
      name: 'CommerceIQ',
      yStart: 2022,
      yEnd: 2024,
      roles: [
        'Software Development Engineer 2'
      ]
    },
    {
      name: 'E.fundamentals',
      yStart: 2018,
      yEnd: 2022,
      roles: [
        'Software Engineer',
        'Junior Software Engineer',
        'Apprentice Developer'
      ]
    }
  ];

  const getTimeline = () => {
    const timelineStructure = [];
    let lastColor;

    career.forEach((job, idxJob) => {
      const randomColor = getRandomColor(lastColor);
      const jobPoint = TimelineJob(randomColor[500], job.name, job.yStart, job.yEnd);
      timelineStructure.push(jobPoint);

      const isLastJob = career.length == idxJob + 1;
      job.roles.forEach((role, idxRole) => {
        const isLast = job.roles.length == idxRole + 1 && isLastJob;
        const rolePoint = TimelineRole(randomColor[200], role, isLast);
        timelineStructure.push(rolePoint);
      });

      lastColor = randomColor[500];
    });

    return timelineStructure;
  };

  return (
    <Box padding={isMobile ? 1 : 3} id='Experience'>
      <Typography variant='h5'>Experience</Typography>
      <Grid container spacing={isMobile ? 4 : 2} padding={isMobile ? 1 : 3}>
        <Grid item md={4}>
          <Typography variant='h6' textAlign='center'>My Skills</Typography>
          <Box>
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
              {skills.map((tag) => (
                <Grid item key={tag}>
                  {ClickableChip(tag)}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item md xs={12}>
          <Typography variant='h6' textAlign='center'>Career</Typography>
          <Timeline position='left'>
            {getTimeline()}
          </Timeline>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Experience;