import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import './index.css';
import App from './components/App';

const firebaseConfig = {
  apiKey: 'AIzaSyB2xyxtlPA3YMJM0YzlfSun6I-kKvr6RtY',
  authDomain: 'lauriemciver-main.firebaseapp.com',
  projectId: 'lauriemciver-main',
  storageBucket: 'lauriemciver-main.appspot.com',
  messagingSenderId: '332069223979',
  appId: '1:332069223979:web:7eff6bb108e7bf8395f46e',
  measurementId: 'G-RVHGJNETQE'
};

const mainTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);