import React from 'react';
import PropTypes from 'prop-types';
import { Gltf } from '@react-three/drei';

function RRComputerModel(props) {
  const exOffst = props.explodeOffset / 10;
  return (
    <group>
      <Gltf src='/assets/RpiRallyComputer/Casing.glb' position={[0, 0, exOffst * 4]} />
      <group>
        <Gltf src='/assets/RpiRallyComputer/Screen.glb' position={[0, 0, -exOffst * 2]} />
        <group>
          <Gltf src='/assets/RpiRallyComputer/PiMount.glb' position={[0, 0, -exOffst * 5]} />
          <Gltf src='/assets/RpiRallyComputer/RPi.glb' position={[0, 0, -exOffst * 7]} />
        </group>
      </group>
      <Gltf src='/assets/RpiRallyComputer/NumPad.glb' position={[0, 0, exOffst * 8]} />
      <Gltf src='/assets/RpiRallyComputer/PwrSwitch.glb' position={[0, 0, -exOffst * 6]} />
      <Gltf src='/assets/RpiRallyComputer/BuckConverter.glb' position={[0, 0, -exOffst * 5]} />
    </group>
  );
}

RRComputerModel.propTypes = {
  explodeOffset: PropTypes.number
};

export default RRComputerModel;