import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

export function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sizes = props.isTitle ? ['h3', 'h4'] : ['h4', 'h5'];

  return (<Typography variant={isMobile ? sizes[1] : sizes[0]} textAlign={props.textAlign}>{props.children}</Typography>);
}

Header.propTypes = {
  children: PropTypes.object,
  isTitle: PropTypes.bool,
  textAlign: PropTypes.string
};