import { Avatar, Box, Card, CardContent, Chip, Paper, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

function ProjectCard(props) {
  const [cardStyle, setCardStyle] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const cardRef = useRef(null);

  /* useEffect(() => {
    const handleDeviceOrientation = (event) => {
      const gamma = event.gamma; // Rotation around the front-to-back axis
      const beta = event.beta;   // Rotation around the side-to-side axis

      const perspectiveX = gamma * 0.5; // Adjust the sensitivity as needed
      const perspectiveY = beta * 0.5;   // Adjust the sensitivity as needed

      const newStyle = {
        transform: `perspective(1000px) rotateY(${perspectiveX}deg) rotateX(${perspectiveY}deg)`
      };

      setCardStyle(newStyle);
    };

    window.addEventListener('deviceorientation', handleDeviceOrientation);

    return () => {
      window.removeEventListener('deviceorientation', handleDeviceOrientation);
    };
  }, []); */

  const handleMouseMove = (event) => {
    const card = cardRef.current;
    const cardRect = card.getBoundingClientRect();
    const offsetX = event.clientX - cardRect.left;
    const offsetY = event.clientY - cardRect.top;

    const perspectiveX = (offsetX / cardRect.width - 0.5) * 10; // Adjust the perspective factor as needed
    const perspectiveY = (offsetY / cardRect.height - 0.5) * -10; // Adjust the perspective factor as needed

    const newStyle = {
      transform: `perspective(1000px) rotateY(${perspectiveX}deg) rotateX(${perspectiveY}deg)`
    };

    setCardStyle(newStyle);
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setCardStyle({});
    setIsHovered(false);
  };

  return (
    <Paper
      elevation={isHovered ? 8 : 3}
      ref={cardRef}
      onMouseMove={handleMouseMove}
      onMouseOut={handleMouseOut}
      sx={{ ...cardStyle, height: '100%', cursor: 'pointer' }}
      onClick={() => props.onProjectClick(props.projectId)}
    >
      <Card
        sx={{ maxWidth: 345, height: '100%' }}
      >
        <CardContent>
          <Box display='flex' justifyContent='center'>
            <Avatar
              src={`/images/${props.image}`}
              alt={props.title}
              sx={{ width: '50%', height: '50%' }}
            />
          </Box>
          <Typography variant='h5' component='div' marginY={2}>
            {props.title}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {props.summary}
          </Typography>
          <Box>
            <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
              {props.tags.map((tag) => (
                <Grid item key={tag}>
                  <Chip
                    avatar={<Avatar alt={tag} src={`/images/icons/${tag.replace(/\s/g, '').toLowerCase()}.svg`} />}
                    variant='outlined'
                    label={tag}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
}

ProjectCard.propTypes = {
  projectId: PropTypes.string,
  title: PropTypes.string,
  summary: PropTypes.string,
  image: PropTypes.string,
  tags: PropTypes.array,
  onProjectClick: PropTypes.func
};

ProjectCard.defaultProps = {
  tags: []
};

export default ProjectCard;
