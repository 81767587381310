/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DoorbellModel from './DoorbellModel';
import ModelViewer from '../../ModelViewer';
import { Header } from '../../utils';

function PiDoorbell() {
  return (
    <>
      <Header isTitle textAlign='center'>RPi Video Doorbell</Header>
      <ModelViewer rotation={[0.5, 0.9, 0]}>
        <DoorbellModel />
      </ModelViewer>
      <Box width='90%'>
        <Header>Requirements</Header>
        <Typography>
          The client required a doorbell camera system that fulfilled two primary functions: acting as a simple doorbell and allowing the client to view live presence at the door at any time. Existing systems such as Amazon&apos;s Ring doorbell did not meet these requirements for several reasons.
          <p>
            Most of these systems are designed for use with a smartphone, which the client did not possess. They also require subscriptions to services that may become unavailable. Additionally, the Ring Doorbell needed to be paired with an Echo Show to display a standalone video feed and was only active as a notification when the doorbell was rung.
          </p>
          <p>
            <b>Therefore, the requirements for the system were as follows:</b>
            <ul>
              <li>Must operate 24/7.</li>
              <li>Must alert the client with a sound when the bell button is pressed.</li>
              <li>Must always display the video feed.</li>
              <li>Should be simple and avoid an overcomplicated UI.</li>
              <li>Should indicate to the user at the doorbell that it is functioning when pressed.</li>
              <li>Should be viewable by other devices on the network.</li>
            </ul>
          </p>
        </Typography>
        <Header>Design & Implementation</Header>
        <Typography>
          At the outset of this project, I decided to use Raspberry Pi for both the doorbell and the receiving unit. I utilized a Pi Zero for the doorbell, as it has sufficient power to capture video through a Pi-Cam and stream the feed, as well as run a simple NodeJS application with an Express server to relay a call to the receiving unit when the doorbell is pressed. Additionally, it provides system data back to the receiving unit via a health check endpoint.
          <p>
            For the receiving unit, I used a Raspberry Pi 4 B (2GB), which hosts a 7-inch touch screen for displaying the camera feed and a USB speaker module for playing the doorbell audio. This unit runs another NodeJS application with an ExpressJS server. The server includes several endpoints: a health check, an endpoint for the doorbell signal, and an endpoint serving the web page for users to connect to.
          </p>
          <p>
            Upon startup, both systems are configured to launch their required applications. For the receiving unit, this includes launching a full-screen Chromium browser to display the web page with the camera feed and the capability to play audio from the page. The page also displays system information from the doorbell device, the current date and time, and includes a button that allows the client to capture an image from the doorbell camera and send it via email to a predefined list of addresses.
          </p>
        </Typography>
        <Header>Operation & Error Flow</Header>
        <Box display='flex' justifyContent='center' flexDirection='column'>
          <Box
            component='img'
            src='/images/diagrams/DoorbellButtonFlow.svg'
            width='100%'
            alt='Flow chart diagram showing normal and error doorbell button operations.'
          />
          <Box
            component='img'
            src='/images/diagrams/DoorbellHealthFlow.svg'
            width='100%'
            alt='Flow chart diagram showing health check operations from both units.'
          />
        </Box>
        <Header>Learning & Improvement</Header>
        <Grid container>
          <Grid item lg alignItems='center'>
            <Typography>
              After designing an initial version of the RPi Video Doorbell, several issues arose that required improvements in both the physical design of the doorbell unit and the software.
              <p>
                Regarding the physical design of the doorbell unit, the initial version was a square box mounted flat against the wall at a 90-degree angle to the door. This design resulted in blind spots, even with a 180-degree FOV lens on the camera, allowing a person to stand at the door without being seen. The second version (as shown in the 3D design above) addressed this issue by incorporating all the functionality and features of the first version into a slim vertical form. This redesign allowed for angling the unit and mounting it on the doorframe, thus eliminating the blind spots.
              </p>
              <p>
                On the software side, issues such as memory leaks and other unforeseen errors caused the receiving unit to lose connection with the doorbell unit when it would crash or lock up. This was primarily resolved by adjusting firmware settings on the RPi Zero and introducing scheduled reboots to periodically flush the system. Additionally, the unit was integrated with an uptime monitoring service to provide notifications in the event of a crash or lockup.
              </p>
            </Typography>
          </Grid>
          <Grid item lg display='flex' justifyContent='center'>
            <Box
              component='img'
              src='/images/projectImages/DoorbellOld.png'
              width='80%'
              alt='An image of the old cube doorbell design as well as it being open.'
              sx={{
                borderStyle: 'solid',
                borderRadius: '2%'
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PiDoorbell;