import React from 'react';
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { EmailIcon, GithubIcon, LinkedInIcon } from './icons';

function Contact() {

  return (
    <Box marginTop={3} id='ContactLinks'>
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant='h5'>Contact & Links</Typography>
        <Grid container spacing={2} padding={1} justifyContent='center'>
          <Grid item>
            <Tooltip title='Email'>
              <IconButton
                size='large'
                rel='noopener noreferrer'
                href='mailto:lauriemciver1999@gmail.com'
              >
                <EmailIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='LinkedIn'>
              <IconButton
                size='large'
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.linkedin.com/in/laurie-mciver'
              >
                <LinkedInIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='Github'>
              <IconButton
                size='large'
                rel='noopener noreferrer'
                target='_blank'
                href='https://github.com/TheMightyPilot'
              >
                <GithubIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Contact;